import Layout from 'layouts'
import PermissionsGate from 'usecases/permissionGate'
import { Scope } from 'usecases/permissionGate/types/scope'
import Title from 'shared/components/Title'
import Fade from 'shared/components/Fade'
import Card from 'shared/components/Card'
import styles from './styles.module.scss'
import useRequestEndpoint from 'shared/hooks/useRequestEndpoint'
import { IPurchaseOrder } from 'modules/purchase-order/types/purchaseOrder.interface'
import { syncSearch } from 'shared/services/api/search'
import Notification from './components/notification'

const Home = () => {
    const [purchaseOrders]: [IPurchaseOrder[]] = useRequestEndpoint(`/purchase-orders?status=order_placed`)
    const [orders]: [any] = useRequestEndpoint(`/orders?status=open&page=0`)
    const [ordersCount]: [any] = useRequestEndpoint(`/orders/count`)
    const [products]: [any] = useRequestEndpoint(`/products?page=0`)
    const [customers]: [any] = useRequestEndpoint(`/customers?type=b2c`)
    const [nosProducts]: [any] = useRequestEndpoint(`/products?page=0&isNos=true`)

    const handleSyncSearch = async () => {
        await syncSearch()
    }

    return (
        <Layout>
            <PermissionsGate requiredScopes={[Scope.canView, Scope.canCreate]}>
                <Fade show="1">
                    <Card main>
                        <Title title="Overview"></Title>
                        <div className={`is-flex ${styles.boxes}`}>
                            <div className={styles.box}>
                                <div className={styles.boxTitle}>Purchase orders</div>
                                {purchaseOrders?.length}
                            </div>
                            <div className={styles.box}>
                                <div className={styles.boxTitle}>Open orders</div>
                                {orders?.count}
                            </div>
                            <div className={styles.box}>
                                <div className={styles.boxTitle}>Total orders</div>
                                {ordersCount?.count}
                            </div>
                            <div className={styles.box}>
                                <div className={styles.boxTitle}>Products</div>
                                {products?.count}
                            </div>
                            <div className={styles.box}>
                                <div className={styles.boxTitle}>Customers</div>
                                {customers?.length}
                            </div>
                            <div className={styles.box}>
                                <div className={styles.boxTitle}>NOS</div>
                                {nosProducts?.count}
                            </div>
                        </div>
                        <div>
                            <Notification />
                        </div>
                    </Card>
                </Fade>
            </PermissionsGate>
        </Layout>
    )
}

export default Home
