import { formatDate } from 'shared/utils/formatDate'
import Link from 'shared/components/Link'
import styles from '../../styles.module.scss'
import useRequestEndpoint from 'shared/hooks/useRequestEndpoint'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import User from 'shared/images/user.svg'
import { useEffect } from 'react'

export default function Notification() {
    const [notifications]: [any] = useRequestEndpoint(`/notifications`)

    useEffect(() => {
        TimeAgo.addDefaultLocale(en)
    }, [])

    return notifications?.map((notification: any) => {
        const timeAgo = new TimeAgo('en-US')
        timeAgo.format(new Date(notification.createdAt))

        return (
            <div key={notification.id} className={`${styles.notification}`}>
                <Link href={`/${notification.resource}/${notification.resourceId}`}>
                    <div className="is-flex mb-5">
                        <div className="mr-3">
                            {notification.createdBy.image && <img src={notification.createdBy.image} />}
                            {!notification.createdBy.image && (
                                <div className={styles.notificationImage}>
                                    <User />
                                </div>
                            )}
                        </div>
                        <div>
                            {notification.createdBy.name} {notification.event}
                            <div className={styles.notificationTime}>{formatDate(notification.createdAt, 'HH:mm')} </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    })
}
