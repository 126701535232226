import { put, post, del, get } from 'shared/utils/restClient'
import { getEnvironmentVariable } from 'shared/utils/getEnvironmentVariable'
import storage from 'local-storage-fallback'

const token: string | null = storage.getItem('didToken')
const apiUrl = getEnvironmentVariable('apiUrl')

export const syncSearch = async () => {
    const response = await get(`${apiUrl}/search/sync`, token)
    return response.data
}
