import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import storage from 'local-storage-fallback'

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.timeout = 12000

const getHttpHeaders = (token: string | null, formData: boolean | null = false): AxiosRequestConfig => {
    const authToken = storage.getItem('token') //TODO: REFACTOR THIS TO IT DOES NOT USE OWN ITEM FROM LOCAL STORAGE
    const headers: any = {}

    // Add Authorization header if token is present
    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`
    }

    // Conditionally set the Content-Type header based on formData
    if (formData) {
        headers['Content-Type'] = 'multipart/form-data'
    }

    return {
        headers,
    }
}

const get = (path: string, token: string | null): Promise<AxiosResponse> => axios.get(path, getHttpHeaders(token))

const del = (path: string, token: string | null): Promise<AxiosResponse> => axios.delete(path, getHttpHeaders(token))

const post = async (path: string, data: any, token: string | null, hasFormData: boolean | null = false) => {
    const response = await axios.post(path, data, getHttpHeaders(token, hasFormData))
    return await response
}

const put = async (path: string, data: any, token: string | null) => {
    const response = await axios.put(path, data, getHttpHeaders(token))
    return await response
}

const patch = (path: string, data: any, token: string | null): Promise<AxiosResponse> => axios.post(path, data, getHttpHeaders(token))

export { get, del, put, post, patch }
