import type { NextPage } from 'next'
import Home from 'modules/home'

const HomePage: NextPage = () => {
    return <Home />
}

// HomePage.getInitialProps = async (ctx) => {
//     console.log('gogo')
//     return {}
// }

export default HomePage
